@import 'colors.scss';
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}
* {
  box-sizing: border-box;
}

html,body {
  height:100%;
  min-height:100%;
}

body{
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color:$darkgrey;
  margin: 0;
}

[ng-click],
[data-ng-click],
[x-ng-click] {
    cursor: pointer;
}
