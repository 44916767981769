@import "../../../../styles/colors.scss";
.tb-pop-up-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  margin-right: 8px;
  margin-top: 14px;
  &_icon {
    align-items: flex-end;
    border: 1px;
    display: block;
    width: 36px;
    i {
      width: 36px;
    }
    &_block {
      width: 36px;
      height: 36px;
      display: block;
      background: url("assets/img/icons/block.svg") no-repeat 50%;
    }
    &_relation {
      width: 36px;
      height: 36px;
      display: block;
      background: url("assets/img/icons/relation.svg") no-repeat 50%;
    }
    &_calendar {
      width: 36px;
      height: 36px;
      display: block;
      background: url("assets/img/icons/calendar.svg") no-repeat 50%;
    }
    &_length {
      width: 36px;
      height: 36px;
      display: block;
      background: url("assets/img/icons/calendar-recurring.svg") no-repeat 50%;
    }
    &_arrow-left {
      width: 36px;
      height: 36px;
      display: block;
      background: url("assets/img/icons/arrow_left.svg") no-repeat 50%;
    }
    &_arrow-left-small {
      width: 20px;
      height: 36px;
      margin-left: 8px;
      display: block;
      background: url("assets/img/icons/arrow_left.svg") no-repeat 50%;
      background-size: 20px 20px;
    }
    &_invoice {
      width: 36px;
      height: 36px;
      display: block;
      background: url("assets/img/icons/invoice.svg") no-repeat 50%;
    }
    &_robot {
      width: 36px;
      height: 36px;
      display: block;
      background: url("assets/img/icons/tessa-dark.svg") no-repeat 50%;
    }
    &_box {
      width: 36px;
      height: 36px;
      display: block;
      background: url("assets/img/icons/category.svg") no-repeat 50%;
    }
    &_amount {
      width: 36px;
      height: 36px;
      display: block;
      background: url("assets/img/icons/amount.svg") no-repeat 50%;
    }
    &_address {
      width: 36px;
      height: 36px;
      display: block;
      background: url("assets/img/icons/language.svg") no-repeat 50%;
    }
    &_arrows {
      width: 36px;
      height: 36px;
      display: block;
      background: url("assets/img/icons/compare_arrows-24px.svg") no-repeat 50%;
    }
    &_heartbeat {
      width: 36px;
      height: 36px;
      display: block;
      background: url("assets/img/icons/TB-DB_icon_heartbeat.svg") no-repeat 50%;
      filter: invert(100%);
    }
    &_ship {
      width: 36px;
      height: 36px;
      display: block;
      background: url("assets/img/icons/TB-DB_icon_Shipping.svg") no-repeat 50%;
    }
    &_zendesk {
      width: 36px;
      height: 36px;
      display: block;
      background: url("assets/img/icons/tb_zendesk.png") no-repeat 50%;
    }
    &_subref {
      width: 36px;
      height: 36px;
      display: block;
      background: url("assets/img/icons/subref.svg") no-repeat 50%;
    }
    &_premium {
      width: 36px;
      height: 36px;
      display: block;
      background: url("assets/img/icons/tb-db-icon-pay-ref.svg") no-repeat 50%;
    }
    &_subs {
      width: 36px;
      height: 36px;
      display: block;
      background: url("assets/img/icons/tb-db-icon-subscription-amount.svg") no-repeat 50%;
      background-size: 14px;
    }
  }
  &_item {
    border: 1px;
    display: flex;
    flex-flow: column nowrap;
    margin-left: 8px;
    &_center {
      align-items: center;
    }
    &_disabled {
      opacity: 0.25;
    }
    &_item_item_centerbox {
      display: flex;
      justify-content: center;
      margin-left: 8px;
    }
    &_number {
      font-family: Roboto-Mono-Light;
    }
    &_small {
      width: 190px !important;
    }
    &_fullbox {
      border: 1px solid $darkgrey !important;
    }
    &_label {
      height: 14px;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: bold;
      color: $darkgrey;
      &_error {
        color: $warning;
      }
      &_short {
        width: auto;
      }
      &_selected {
        text-decoration: underline;
        margin-right: 8px;
      }
      &_not-selected {
        opacity: 0.5;
        margin-right: 8px;
      }
    }
    &_info {
      width: 213px;
      font-family: Roboto-Mono-Light;
      font-size: 16px;
      color: $lightgrey;
      font-weight: normal;
      margin: 0px;
      padding: 0px;
      background-color: transparent !important;
      border: none;
      height: 28px !important;
      font-size: 16px !important;
      padding-top: 0;
      padding-bottom: 0;
      line-height: 28px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &_long {
        width: 426px;
        font-family: Montserrat;
        opacity: 0.7;
      }
      &_warn {
        color: $warning;
        font-family: Montserrat;
        opacity: 0.7;
      }
    }
    &_information {
      width: 455px;
      font-family: Roboto-Mono-Light;
      color: $lightgrey;
      font-weight: normal;
      opacity: 0.7;
      font-size: 16px !important;
      padding-top: 0;
      padding-bottom: 0;
      line-height: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      &_error {
        color: $warning;
        opacity: 1;
        margin: 0px;
        font-size: 12px !important;
      }
    }
    &_log {
      width: 440px;
      height: 180px;
      border: solid 1px #707070;
      background-color: #e8e4da;
      overflow-y: scroll;
      opacity: 0.6;
      font-family: Montserrat;
      font-size: 14px;
      color: #333333;
      padding-left: 10px;
    }
    &_hyperlink {
      width: 213px;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
      display: flex;
      color: #333333;
      &_number {
        font-family: Roboto-Mono-Light;
      }
      &_arrow {
        height: 31px;
        width: 24px;
        display: block;
        background: url("assets/img/icons/TB-APP_button_arrow_back-24px.svg") no-repeat 50%;
        background-size: 80%;
      }
    }
    &_additional {
      width: 227px;
      margin: 0px;
      margin-top: -14px;
      display: flex;
      flex-direction: column;
      &_item {
        font-family: Montserrat;
        font-size: 10px;
        width: 227px;
        &_green {
          color: $lightgreen;
        }
        &_warning {
          color: $warning;
        }
      }
    }
    &_input {
      width: 213px;
      margin: 0px;
      padding: 0px;
      background-color: transparent !important;
      border: none;
      border-bottom: 1px solid $darkgrey !important;
      border-radius: 0 0 0px 0px !important;
      height: 28px !important;
      font-size: 16px !important;
      padding-top: 0;
      padding-bottom: 0;
      line-height: normal;
      &_long {
        width: 454px;
      }
      & input {
        &:focus {
          background-color: transparent !important;
        }
      }
    }
    &_middlecheckbox {
      width: 227px;
    }
    &_leftcheckbox {
      width: 227px;
      display: flex;
    }
    &_robot {
      font-family: Roboto-Mono-Light;
    }
    &_robot::-webkit-input-placeholder {
      font-family: Montserrat;
      opacity: 0.4;
    }

    &_robot:-ms-input-placeholder {
      font-family: Montserrat;
      opacity: 0.4;
    }

    &_robot:-moz-placeholder {
      font-family: Montserrat;
      opacity: 0.4;
    }

    &_robot::-moz-placeholder {
      font-family: Montserrat;
      opacity: 0.4;
    }
    &_textarea {
      width: 455px;
      height: 187px;
      border-radius: 2px;
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
      border: 0px;
      padding: 8px;
      background-color: $lightestgrey20;
      font-family: Montserrat;
      line-height: 1.36;
      font-size: 14px;
      resize: none;
      margin-top: 8px;
    }
    &_checkboxes {
      display: flex;
      flex-flow: row nowrap;
      &_label {
        height: 20px;
        font-family: Montserrat;
        font-size: 16px;
        line-height: 20px;
        margin-top: 8px;
        margin-right: 32px;
      }
      &_centerbox {
        display: flex;
        justify-content: center;
      }
      &_unchecked {
        height: 36px;
        width: 36px;
        display: block;
        background: url("assets/img/icons/checkbox-unchecked.svg") no-repeat 50%;
      }
      &_checked {
        height: 36px;
        width: 36px;
        display: block;
        background: url("assets/img/icons/checkbox-checked-darkgreen.svg") no-repeat 50%;
      }
      &_text {
        font-family: Montserrat;
        font-size: 12px;
        width: 117px;
      }
    }
  }

  md-switch {
    margin: 6px;
    margin-top: 8px;
    margin-left: 0px;
    .md-bar {
      background-color: rgba(255, 0, 0, 0.317); /* set not selected bar color */
    }
    .md-thumb {
      background-color: rgb(255, 0, 0); /* not selected switch color */
    }
  }
}
