@import "colors";
.tb{
  &-form-group{
    max-width: 300px;
    margin:35px auto 25px;
  }
  &-input-row{
    position: relative;
    margin-bottom:35px;
    input[type=number]{
      -moz-appearance:textfield;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    label{
      display: inline-block;
      max-width: 100%;
      margin-bottom: 5px;
      font-size: 12px;
      color: $darkgrey;
      font-family: Montserrat-Bold;
      font-weight: bold;
      padding-left: 10px;
    }
    &_cpu{
      .tb-cpu-container{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f0eee6;
        padding: 0 18px;
        height: 75px;
        & input {
          max-width:20%;
          -webkit-appearance: none;
          -moz-appearance: none;
          border-top: 0;
          border-bottom: 1px solid #979797;
          font-family: Roboto-Mono;
          font-weight: bolder;
          font-size: 45px;
          height:45px;
          line-height:45px;
          padding:0;
          margin-right: 15px;
          text-align: center;
          background-color: #f0eee6;
          border-bottom: 1px solid #979797;
          box-shadow: none;
          @media only screen and (max-width: 450px){
            height: 55px !important;
          }
          &:last-of-type{
            margin-right: 0;
          }
          &:focus{
            background-color: #f0eee6;
            outline: none;
            appearance:none;
            -webkit-appearance: none;
            -moz-appearance: none;
          }
        }
      }
    }
    input{
      width: 100%;
      height:30px;
      box-sizing: border-box;
      line-height: 30px;
      border:0;
      border-bottom:1px solid #979797;
      background-color: inherit;
      padding-left:10px;
      border-radius:0;
      font-family: Montserrat;
      font-size:18px;
      color: $darkgrey;
      &:-webkit-autofill{
        background-color: $cremewhite!important;
        -webkit-text-fill-color: $darkgrey;
        -webkit-box-shadow: 0 0 0 1000px $cremewhite inset;
      }

      &:focus{
        background-color:inherit!important;
      }
    }
    &__error{
      display: inline-block;
      position: absolute;
      top:55px;
      left:10px;
      color:$warning;
      font-size:12px;
      font-family:Montserrat;
    }
    &__password-strength{
      position: absolute;
      top: 55px;
      left: 0;
      right: 0;
      &-line{
        height: 5px;
        &_strong{
          background: url('assets/img/icons/password-strength-sterk.svg') 0 0;
        }
        &_weak{
          background: url('assets/img/icons/password-strength-zwak.svg') 0 0 no-repeat;
        }
      }
      &-status{
        padding: 5px 10px 0 0;
        font-family: Montserrat;
        font-size:12px;
        color: #9a9183;
        text-align: right;
      }
    }
    &__icon{
      display: block;
      position:absolute;
      width:20px;
      height:20px;
      left:-30px;
      top:35px;
      &_checkbox-field{
        top:0;
      }
      &_mail{
        background:url('assets/img/icons/email.svg') no-repeat 50%;
      }
      &_link{
        background:url('assets/img/icons/link.svg') no-repeat 50%;
      }
      &_password{
        background:url('assets/img/icons/password.svg') no-repeat 50%;
      }
      &_person{
        background:url('assets/img/icons/person.svg') no-repeat 50%;
      }
      &_phone{
        background:url('assets/img/icons/phone.svg') no-repeat 50%;
      }
      &_field-status{
        width: 15px;
        height: 15px;
        right: -25px;
        left: auto;
        &-success{
          background:url('assets/img/icons/check-small.svg') no-repeat 50%;
        }
        &-error{
          background:url('assets/img/icons/error-small.svg') no-repeat 50%;
        }
      }
    }
  }
  &-checkbox-row{
    display: flex;
    align-items: center;
    position: relative;
    height: 20px;
    input[type=checkbox]{
      height: 20px;
      position: absolute;
      left:0;
      top:0;
      margin:0;
      visibility:hidden;
    }
    label{
      font-family: Montserrat;
      font-size:12px;
      margin: 0 0 0 10px;
      line-height:20px;
      max-width: 88%;
      span{
        font-family: Montserrat-Bold;
        text-decoration: underline;
        font-weight: 600;
      }
      &:hover{
        cursor: pointer;
      }
    }
    &_multiline{
      height: 60px;
      align-items: flex-start;
    }
    &__icon{
      display: inline-block;
      width: 20px;
      height: 20px;
      &:hover{
        cursor:pointer;
      }
      &_unchecked{
        background:url('assets/img/icons/checkbox-unchecked.svg') no-repeat 50%;
      }
      &_checked{
        background:url('assets/img/icons/checkbox-checked-darkgreen.svg') no-repeat 50%;
      }
    }
    &_invisible > *{
      display: none;
    }
  }
  &-button-row{
    text-align: right;
    margin-top:25px;
    &_margin-increased{
      margin-top:65px;
    }
  }
}
