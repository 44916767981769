@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.app{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 60px;
  background-color: white;
}

_:-ms-lang(x), _:-webkit-full-screen, .app {
  padding-bottom: 95px;
}
_:-ms-fullscreen, :root .app {
  padding-bottom: 95px;
}
