@import '../../../../styles/colors.scss';
.tb-pop-up-v2{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1040;
  &_hidden{
    display: none;
  }
  &_top {
    @media(max-width: 768px){
      align-items: flex-start;
      padding-top: 8px;
    }
  }
  &_input {
    width: 250px;
    height: 36px;
    padding: 10px 15px;
    border: solid 1px $lightestgrey;
    font-family: Montserrat;
    font-size: 14px;
  }
  &__inner{
    display: flex;
    flex-direction: column;
    min-height:160px;
    max-height: 800px;
    background-color: #ffffff;
    padding:20px;
    border-radius: 3px;
    box-sizing: border-box;
    font-family: Montserrat;
    z-index: 4;
    transition: transform .3s cubic-bezier(.2,.52,.48,1.57);
    @media (max-width: 320px){
      width: 300px;
      padding-left:10px;
      padding-right:10px;
    }
  }
  &__blurred-content{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index:1;
  }
  &__body{
    flex: 1;
    margin-left: 36px;
    &:empty{
      display: none;
    }
  }
  &__titlebox{
    display: flex;
    margin-left: 52px;
    &_left{
      margin-left: 0px;
    }
  }
  &__title{
    margin-top: 44px;
    margin-right: 32px;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: $darkgrey;
    &:empty{
      display: none;
    }
    &_grey{
      color: rgba(0, 0, 0, 0.25)!important;
    }
    &_underlined{
      text-decoration: underline;
    }
  }
  &__title-element{
    font-family: Montserrat-Bold;
    &_bold{
      font-family: Montserrat-Bold;
    }
    &_warning{
      color:$warning;
    }
  }
  &__text{
    margin: 15px 0;
    font-size: 14px;
    line-height: 18px;
    color: $darkgrey;
    &_warning{
      color:$warning;
      &:hover{
        color:$warning;
      }
    }
    &:empty{
      display: none;
    }
  }
  &__text-element{
    font-family: Montserrat-Bold;
    font-size: 16px;
    &_warning{
      color:$warning;
      &:hover{
        color:$warning;
      }
    }
    &_bold{
      font-size: 16px;
      font-family: Montserrat-Bold;
    }
  }
  &__footer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top:25px;
  }
  &__additional-info{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top:15px;
    padding-left: 15px;
    font-family: Montserrat-Bold;
    font-size:12px;
    color:$warning;
    &-icon{
      display: block;
      width: 15px;
      height: 15px;
      background:url('assets/img/icons/error-small.svg') no-repeat 50%;
      margin-right: 10px;
    }
  }
  &__button{
    font-family: Montserrat;
    margin-left:20px;
    outline:none;
    padding:0;
    font-size: 14px;

    text-transform: uppercase;

    background:none;
    border: none;
    &_cancel {
      color:$lightestgrey;
      text-align: end;
    }
    &_submit{
      //width: 45%;
      padding: 5px 10px;
      min-height: 36px;
      max-height: 50px;
      text-align: center;
      border-radius: 4px;
    }
    &_warning{
      color: $warning;
      border: 1px solid $warning;
    }
    &_green {
      color: $lightgreen;
      border: 1px solid $lightgreen;
    }
    &:hover{
      cursor:pointer
    }
  }
  &__tips{
    margin-bottom:15px;
    &-icon{
      background: url("assets/img/icons/check-arrow-light.svg") no-repeat 50%;
      width: 15px;
      min-width: 15px;
      height: 16px;
      margin-right: 20px;
    }
    &-message{
      font-size: 12px;
      font-family:  Montserrat;
      color: $lightgreen;
    }
    &-wrapper{
      margin-top:25px;
    }
  }
  &__tip{
    display: flex;
    align-items: flex-start;
    padding-left: 15px;

  }
  &_cancel{
    .tb-pop-up-v2__title{
      color: #000;
      margin: 0px;
      font-size: 20px;
      text-decoration: none;
    }
    .tb-pop-up-v2__text-element{
      color: #8356ac;
      &:hover{
        color: #8356ac;
      }
    }
    .tb-pop-up-v2__button{
      &_submit{
        color: white;
        border: 1px solid #8356ac;
        background-color: #8356ac;
      }
      &_cancel {
        color: #8356ac;
      }
    }
  }
  &_warning{
    .tb-pop-up-v2__title{
      color:$warning;
      margin: 0px;
    }
    .tb-pop-up-v2__text-element{
      color: $warning;
      &:hover{
        color:$warning;
      }
    }
    .tb-pop-up-v2__button{
      &_submit{
        color: $warning;
        border: 1px solid $warning;
      }
    }
  }
  &_subscription{
    .tb-pop-up-v2__title{
      color: #8356ac;
    }
    .tb-pop-up-v2__text-element{
      color: #8356ac;
      &:hover{
        color: #8356ac;
      }
    }
    .tb-pop-up-v2__button{
      &_submit{
        color: white;
        border: 1px solid #8356ac;
        background-color: #8356ac;
      }
      &_cancel {
        color: #8356ac;;
      }
    }
  }
  &_add_subscription, &_organisation{
    .tb-pop-up-v2__title{
      color: #3f51b5;
    }
    .tb-pop-up-v2__text-element{
      color: #3f51b5;
      &:hover{
        color: #3f51b5;
      }
    }
    .tb-pop-up-v2__button{
      &_submit{
        color: white;
        border: 1px solid #3f51b5;
        background-color: #3f51b5;
      }
      &_cancel {
        color: #3f51b5;
      }
    }
  }
  &_robot{
    .tb-pop-up-v2__title{
      color: #1d6494;
    }
    .tb-pop-up-v2__text-element{
      color: #1d6494;
      &:hover{
        color: #1d6494;
      }
    }
    .tb-pop-up-v2__button{
      &_submit{
        color: white;
        border: 1px solid #1d6494;
        background-color: #1d6494;
      }
      &_cancel {
        color: #1d6494;
      }
    }
  }
  &_info {
    .tb-pop-up-v2__title{
      margin: 0px;
    }
    .tb-pop-up-v2__text-element{
      color: $lightgreen;
      &:hover{
        color:$lightgreen;
      }
    }
    .tb-pop-up-v2__button{
      &_submit{
        color: $lightgreen;
        border: 1px solid $lightgreen;
      }
    }
    &--subs-added{
      .tb-pop-up-v2__button{
        &_submit{
          color: white;
          border: 1px solid #3f51b5;
          background-color: #3f51b5;
        }
      }
    }
  }
  &_content{
    width: 1024px;
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    flex-basis: 0;
    justify-content: space-between;
    &_info {
      display: flex;
      flex-flow: column nowrap;
      &_space {
        margin-top: 25px;
      }
      &_bottom {
        margin-bottom: 25px;
      }
      &_parts {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;
        margin-right: 8px;
        margin-top: 0px;
        &_no-top-space{
          margin-top: -14px;
        }
        &_extra-space{
          margin-top: 62px;
        }
        &__icon{
          align-items: flex-end;
          border: 1px;
          display: block;
          margin-right: 8px;
          width: 36px;
          i {
            width: 36px;
          }
          &_arrow_left {
            width: 36px;
            height:36px;
            display: block;
            background: url('assets/img/icons/arrow_left.svg') no-repeat 50%;
          }
          &_relation{
            width: 36px;
            height:36px;
            display: block;
            background: url('assets/img/icons/relation.svg') no-repeat 50%;
          }
          &_calendar{
            width: 36px;
            height:36px;
            display: block;
            background: url('assets/img/icons/calendar.svg') no-repeat 50%;
          }
          &_length{
            width: 36px;
            height:36px;
            display: block;
            background: url('assets/img/icons/calendar-recurring.svg') no-repeat 50%;
          }
          &_invoice{
            width: 36px;
            height:36px;
            display: block;
            background: url('assets/img/icons/invoice.svg') no-repeat 50%;
          }
          &_robot{
            width: 36px;
            height:36px;
            display: block;
            background: url('assets/img/icons/tessa-dark.svg') no-repeat 50%;
          }
          &_box{
            width: 36px;
            height:36px;
            display: block;
            background: url('assets/img/icons/category.svg') no-repeat 50%;
          }
          &_amount{
            width: 36px;
            height:36px;
            display: block;
            background: url('assets/img/icons/amount.svg') no-repeat 50%;
          }
          &_address {
            width: 36px;
            height:36px;
            display: block;
            background: url('assets/img/icons/language.svg') no-repeat 50%;
          }
          &_arrows {
            width: 36px;
            height:36px;
            display: block;
            background: url('assets/img/icons/compare_arrows-24px.svg') no-repeat 50%;
          }
          &_ship {
            width: 36px;
            height:36px;
            display: block;
            background: url('assets/img/icons/TB-DB_icon_Shipping.svg') no-repeat 50%;
          }
        }
        &_combined{
          width: 227px;
          display: flex;
          flex-flow: row;
        }
        &_item{
          border: 1px;
          display: flex;
          flex-flow: column nowrap;
          &_center {
            align-items: center;
          }
          &_centerpart {
            width: 266px;
            align-items: center;
          }
          &_label {
            width: 227px;
            height: 14px;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: bold;
            color: $darkgrey;
            &_error {
              color: $warning;
            }
            &_short {
              width: auto;
            }
          }
          &_info {
            width: 213px;
            font-family: Roboto-Mono;
            font-size: 16px;
            color: $lightgrey;
            font-weight: normal;
            margin: 0px;
            padding: 0px;
            background-color: transparent!important;
            border: none!important;
            height: 28px!important;
            font-size: 16px!important;
            padding-top: 0;
            padding-bottom: 0;
            line-height: 28px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          &_information {
            width: 455px;
            font-family: Roboto-Mono;
            color: $lightgrey;
            font-weight: normal;
            opacity: 0.7;
            font-size: 16px!important;
            padding-top: 0;
            padding-bottom: 0;
            line-height: 28px;
            overflow: hidden;
            text-overflow: ellipsis;
            &_error {
              color: $warning;
              opacity: 1;
              margin: 0px;
              font-size: 12px!important;
            }
          }
          &_log{
            width: 440px;
            height: 180px;
            border: solid 1px #707070;
            background-color: #e8e4da;
            overflow-y: scroll;
            opacity: 0.6;
            font-family: Montserrat;
            font-size: 14px;
            color: #333333;
            padding-left: 10px;
          }
          &_additional{
            min-width: 227px;
            margin: 0px;
            margin-top: -24px;
            display: flex;
            flex-direction: column;
            &_item{
              font-family: Montserrat;
              font-size: 12px;
              min-width: 227px;
              &_green {
                color: $lightgreen;
              }
              &_warning {
                color: $warning;
              }
            }
          }
          &_input {
            width: 213px;
            margin: 0px;
            padding: 0px;
            background-color: transparent!important;
            border: none!important;
            border-bottom: 1px solid $darkgrey!important;
            border-radius: 0 0 0px 0px !important;
            height: 28px!important;
            font-size: 16px!important;
            padding-top: 0;
            padding-bottom: 0;
            line-height: normal;
            &_long {
              width: 454px;
            }
            & input {
              &:focus{
                background-color:transparent!important;
              }
            }
          }
          &_robot {
            font-family: Roboto-Mono;
          }
          &_robot::-webkit-input-placeholder {
            font-family: Montserrat;
          }

          &_robot:-ms-input-placeholder {
            font-family: Montserrat;
          }

          &_robot:-moz-placeholder {
            font-family: Montserrat;
          }

          &_robot::-moz-placeholder {
            font-family: Montserrat;
          }
          &_textarea{
            width: 455px;
            height: 187px;
            border-radius: 2px;
            box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
            border: 0px;
            padding: 8px;
            background-color: $lightestgrey20;
            font-family: Montserrat;
            line-height: 1.36;
            font-size: 14px;
            resize: none;
            margin-top: 8px;
          }
          &_checkboxes{
            display: flex;
            flex-flow: row nowrap;
            &_label {
              height: 20px;
              font-family: Montserrat;
              font-size: 16px;
              line-height: 20px;
              margin-top: 8px;
              margin-right: 32px;
            }
            &_unchecked{
              height: 36px;
              width: 36px;
              display: block;
              background:url('assets/img/icons/checkbox-unchecked.svg') no-repeat 50%;
            }
            &_checked{
              height: 36px;
              width: 36px;
              display: block;
              background:url('assets/img/icons/checkbox-checked-darkgreen.svg') no-repeat 50%;
            }
          }
        }
        &_link {
          font-size: 14px;
          &_teamleader {
            color: #3bb3b2;
            a { text-decoration: none; color:#3bb3b2; }
            a:visited { text-decoration: none; color:#3bb3b2; }
            a:hover { text-decoration: none; color:#3bb3b2; }
            a:focus { text-decoration: none; color:#3bb3b2; }
            a:hover, a:active { text-decoration: none; color:#3bb3b2 }
          }
          &_robot {
            color: #1e6595;
            a { text-decoration: none; color:#1e6595; }
            a:visited { text-decoration: none; color:#1e6595; }
            a:hover { text-decoration: none; color:#1e6595; }
            a:focus { text-decoration: none; color:#1e6595; }
            a:hover, a:active { text-decoration: none; color:#1e6595; }
          }
          &_subscription {
            color: #8356ac;
            a { text-decoration: none; color:#8356ac; }
            a:visited { text-decoration: none; color:#8356ac; }
            a:hover { text-decoration: none; color:#8356ac; }
            a:focus { text-decoration: none; color:#8356ac; }
            a:hover, a:active { text-decoration: none; color:#8356ac; }
          }
                    &_subscription {
            color: #8356ac;
            a { text-decoration: none; color:#8356ac; }
            a:visited { text-decoration: none; color:#8356ac; }
            a:hover { text-decoration: none; color:#8356ac; }
            a:focus { text-decoration: none; color:#8356ac; }
            a:hover, a:active { text-decoration: none; color:#8356ac; }
          }
        }
      }
      &_warning-label {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;
      }
      &_warning {
        font-family: Montserrat;
        font-size: 12px;
        color: $warning;
        padding-left: 36px;
        margin-left: 8px;
        min-width: 219px;
      }
    }
  }
  &_table {
    width: 440px;
    display: flex;
    overflow: ellipsis;
    flex-flow: column nowrap;
    border-bottom: 1px solid #d0d0d0;
    flex: 1 1 auto;
    height: 100%;
    font-size: 12px;
    &_tr {
      width: 100%;
      overflow: ellipsis;
      display: flex;
      font-weight: normal;
      flex-flow: row nowrap;
      &_grey {
        color: $lightestgrey;
      }
    }
    &_th {
      font-family: Montserrat;
      font-size: 10px;
      font-weight: 500;
      z-index: 2;
      a { text-decoration: none; color:white; }
      a:visited { text-decoration: none; color:white; }
      a:hover { text-decoration: none; color:white; }
      a:focus { text-decoration: none; color:white; }
      a:hover, a:active { text-decoration: none; color:white }
    }
    &_td {
      overflow: hidden;
      text-overflow: ellipsis;
      height: 19px;
      line-height: 19px;
      padding-left: 8px;
      &_sort-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      &_length {
        min-width: 80px;
        max-width: 80px;
      }
      &_date {
        min-width: 80px;
        max-width: 80px;
      }
      &_reference {
        min-width: 90px;
        max-width: 90px;
      }
    }
    &_data {
      height: 118px;
      overflow-y: scroll;
      border:1px solid black;
      &_row {
        border-bottom: 1px solid #d0d0d0;
        font-family: Roboto-Mono-Light;
        font-weight: normal;
        font-size: 10px;
      }
    }
  }
}

.tb-pop-up-v2__fixed {
  width: 335px;
}
.ng-enter .tb-pop-up-v2__inner_scalable {
  transform: scale(.85,.85);
}
