@import '../variables';
.tb{
  &-content{
    @media only screen
    and (min-device-width: 340px)
    and (max-device-width: $smaller_width_breakpoint_1)
    and (orientation: landscape) {
      align-items: initial;
      overflow-y: scroll;
    }
    @media only screen
    and (max-device-width: $smaller_width_breakpoint_1)
    and (orientation: portrait){
      overflow-y: hidden;
    }
    @media only screen and (max-device-width: 450px){
      position: static;
    }
    &__inner{
      @media (min-width: 0px) and (max-width: 450px) {
        min-height:100vh;
        max-height: unset;
        width: 100vw;
        height:auto;
        border-radius: 0;
        padding: 20px 40px;
      }
      &_long-text{
        @media (min-width: 0px) and (max-width: 450px) {
          max-height: 100vh;
          overflow-y: auto;
        }
      }
    }
  }

  &-text{
    @media (max-width: 450px){
      margin: 20px auto;
    }
    &_version{
      @media (max-width: 450px){
        position: absolute;
        margin:0 auto;
        bottom: 75px;
        left:0;
        right:0;
      }
      @media (max-width: 350px){
        bottom:60px;
      }
    }
  }
}