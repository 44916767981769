@import '../../../../styles/colors.scss';
@import '../../../../styles/variables.scss';


.auth-warning{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top:0;
  left:0;
  right:0;
  z-index: 2;
  background-color: $warning;
  &__link{
    font-family: Montserrat-Bold;
    color: $cremewhite;
    font-size: 14px;
    text-decoration: underline;
  }
  &__text{
    font-family: Montserrat;
    color: $cremewhite;
    font-size: 14px;
  }
}