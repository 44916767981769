@import 'colors';
.tb{
  &-content{
    display: flex;
    justify-content: center;
    align-items: center;
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    &__inner{
      position: relative;
      background-color:$cremewhite;
      padding:40px;
      max-width: 450px;
      width: 100%;
      box-sizing: border-box;
      margin: 0;
      min-height: 650px;
      max-height: 650px;
      border-radius: 5px;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
      &>div,h1,h3,p{
        max-width: 280px;
        margin-left: auto;
        margin-right: auto;
      }
      ul{
        margin: 0;
        padding-left: 10px;
        font-size: 14px;
        color:$lightgrey;
        font-family: Montserrat;
      }
    }
  }
  &__logo{
    width: 75px;
    height: 75px;
    margin: 0 auto 40px;
    background-size:cover;
    &_main{
      background-image: url('assets/img/logos/tinybots-icon.svg');
    }
    &_error{
      background-image: url('assets/img/logos/tessa-error-large.svg');
    }
  }

  &-title{
    margin: 10px auto;
    opacity: 0.8;
    font-family: Montserrat-Bold;
    font-weight: 600;
    line-height: 1.1;
    text-align: left;
    color: #000000;
    &_l{
      font-size:28px;
    }
    &_s{
      font-size:20px;
    }
    &_error{
      font-family: Montserrat-Bold;
    }
    &_main{
      margin-top:20px;
    }
  }
  &-text{
    margin: 35px auto;
    font-family: Montserrat;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    color: $lightgrey;
    &_containing-image{
      display: flex;
    }
    &_containing-icon{
      position: relative;
    }
    &_email{
      font-family: Montserrat-Bold;
      color:$darkestgrey;
      padding-left:10px;
    }
    &_version{
      font-size:12px;
      opacity:0.4;
      text-align:center;
      margin: 35px auto 0;
    }
  }
  &-button-group{
    display: flex;
    flex-direction: column;
    align-items: center;
    &>button{
      margin-bottom:18px;
    }
  }
  &-link{
    font-size: 12px;
    font-family:  Montserrat;
    color: $darkgrey;
    text-decoration: underline;
    &:focus,&:hover{
      cursor:pointer;
      color: $darkgrey;
    }
  }
  &-question{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 15px;
    &__icon{
      background: url('assets/img/icons/question-small.svg') no-repeat 50%;
      height:15px;
      width:15px;
      margin-right: 15px;
    }
    &__text{
      font-family: Montserrat;
      font-size: 12px;
      color:$lightgrey;
      text-decoration: underline;
      &:hover{
        & + .tb-question__description-wrapper{
          display: block;
        }
      }
    }
    &-arrow {
      width:0;
      height:0;
      border-top:11px solid #ded6c9;
      border-left:10px solid transparent;
      border-right:10px solid transparent;
      position:relative;
      bottom:13px;
      left:-10px;
      &__border{
        position: absolute;
        left: 16px;
        bottom: -11px;
        width: 0;
        height: 0;
        border-top: 12px solid $darkgrey60;
        border-left: 11px solid transparent;
        border-right:11px solid transparent;
      }
    }
    &__description{
      padding: 15px;
      background-color: $beige20;
      border: 1px solid $darkgrey60;
      font-family: Montserrat;
      color:$darkgrey;
      font-size:12px;
      line-height: 1.3;
      position: relative;
      &-wrapper{
        display: none;
        position: absolute;
        bottom: 35px;
        left: -20px;
        right: -20px;
        box-sizing: border-box;
        background-color: $cremewhite;

      }
    }
  }
  &-navigation{
    display: flex;
    align-items:center;
    height: 20px;
    &__back{
      background: url('assets/img/icons/back-arrow.svg') no-repeat 50%;
      height:20px;
      width:20px;
      &:hover{
        cursor:pointer;
      }
    }
  }
}