[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  min-height: 100%;
}

body {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #333;
  margin: 0;
}

[ng-click],
[data-ng-click],
[x-ng-click] {
  cursor: pointer;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
input,
button {
  outline: 0;
}

html:focus,
body:focus,
div:focus,
span:focus,
applet:focus,
object:focus,
iframe:focus,
h1:focus,
h2:focus,
h3:focus,
h4:focus,
h5:focus,
h6:focus,
p:focus,
blockquote:focus,
pre:focus,
a:focus,
abbr:focus,
acronym:focus,
address:focus,
big:focus,
cite:focus,
code:focus,
del:focus,
dfn:focus,
em:focus,
font:focus,
img:focus,
ins:focus,
kbd:focus,
q:focus,
s:focus,
samp:focus,
small:focus,
strike:focus,
strong:focus,
sub:focus,
sup:focus,
tt:focus,
var:focus,
dl:focus,
dt:focus,
dd:focus,
ol:focus,
ul:focus,
li:focus,
fieldset:focus,
form:focus,
label:focus,
legend:focus,
table:focus,
caption:focus,
tbody:focus,
tfoot:focus,
thead:focus,
tr:focus,
th:focus,
td:focus,
input:focus,
button:focus {
  outline: 0;
}

@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  src: url("../assets/fonts/Montserrat-regular/Montserrat-regular.eot");
  src: url("../assets/fonts/Montserrat-regular/Montserrat-regular.eot") format("embedded-opentype"),local("Montserrat Regular"),local("Montserrat-regular"),url("../assets/fonts/Montserrat-regular/Montserrat-regular.woff2") format("woff2"),url("../assets/fonts/Montserrat-regular/Montserrat-regular.woff") format("woff"),url("../assets/fonts/Montserrat-regular/Montserrat-regular.ttf") format("truetype"),url("../assets/fonts/Montserrat-regular/Montserrat-regular.svg") format("svg");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../assets/fonts/Montserrat-semibold/Montserrat-SemiBold.ttf") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("../assets/fonts/Montserrat-bold/Montserrat-Bold.eot") format("eot"),url("../assets/fonts/Montserrat-bold/Montserrat-Bold.woff") format("woff"),url("../assets/fonts/Montserrat-bold/Montserrat-Bold.ttf") format("truetype"),url("../assets/fonts/Montserrat-bold/Montserrat-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-Light";
  font-weight: 300;
  font-style: normal;
  src: url("../assets/fonts/Montserrat-light/montserratlight.woff2") format("woff2"),url("../assets/fonts/Montserrat-light/montserratlight.woff") format("woff"),url("../assets/fonts/Montserrat-regular/Montserrat-regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Mono";
  src: url("../assets/fonts/Roboto-mono/RobotoMono-Bold.ttf");
}

@font-face {
  font-family: "Roboto-Mono-Light";
  src: url("../assets/fonts/Roboto-mono/RobotoMono-Light.ttf");
}

.tb-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.tb-content__inner {
  position: relative;
  background-color: #e8e4da;
  padding: 40px;
  max-width: 450px;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  min-height: 650px;
  max-height: 650px;
  border-radius: 5px;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,.2),0 1px 18px 0 rgba(0,0,0,.12),0 6px 10px 0 rgba(0,0,0,.14);
}

.tb-content__inner>div,
.tb-content__inner h1,
.tb-content__inner h3,
.tb-content__inner p {
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
}

.tb-content__inner ul {
  margin: 0;
  padding-left: 10px;
  font-size: 14px;
  color: #6f6f6f;
  font-family: Montserrat;
}

.tb__logo {
  width: 75px;
  height: 75px;
  margin: 0 auto 40px;
  background-size: cover;
}

.tb__logo_main {
  background-image: url("../assets/img/logos/tinybots-icon.svg");
}

.tb__logo_error {
  background-image: url("../assets/img/logos/tessa-error-large.svg");
}

.tb-title {
  margin: 10px auto;
  opacity: .8;
  font-family: Montserrat-Bold;
  font-weight: 600;
  line-height: 1.1;
  text-align: left;
  color: #000;
}

.tb-title_l {
  font-size: 28px;
}

.tb-title_s {
  font-size: 20px;
}

.tb-title_error {
  font-family: Montserrat-Bold;
}

.tb-title_main {
  margin-top: 20px;
}

.tb-text {
  margin: 35px auto;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #6f6f6f;
}

.tb-text_containing-image {
  display: flex;
}

.tb-text_containing-icon {
  position: relative;
}

.tb-text_email {
  font-family: Montserrat-Bold;
  color: #090909;
  padding-left: 10px;
}

.tb-text_version {
  font-size: 12px;
  opacity: .4;
  text-align: center;
  margin: 35px auto 0;
}

.tb-button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tb-button-group>button {
  margin-bottom: 18px;
}

.tb-link {
  font-size: 12px;
  font-family: Montserrat;
  color: #333;
  text-decoration: underline;
}

.tb-link:focus,
.tb-link:hover {
  cursor: pointer;
  color: #333;
}

.tb-question {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 15px;
}

.tb-question__icon {
  background: url("../assets/img/icons/question-small.svg") no-repeat 50%;
  height: 15px;
  width: 15px;
  margin-right: 15px;
}

.tb-question__text {
  font-family: Montserrat;
  font-size: 12px;
  color: #6f6f6f;
  text-decoration: underline;
}

.tb-question__text:hover+.tb-question__description-wrapper {
  display: block;
}

.tb-question-arrow {
  width: 0;
  height: 0;
  border-top: 11px solid #ded6c9;
  border-left: 10px solid rgba(0,0,0,0);
  border-right: 10px solid rgba(0,0,0,0);
  position: relative;
  bottom: 13px;
  left: -10px;
}

.tb-question-arrow__border {
  position: absolute;
  left: 16px;
  bottom: -11px;
  width: 0;
  height: 0;
  border-top: 12px solid rgba(51,51,51,.6);
  border-left: 11px solid rgba(0,0,0,0);
  border-right: 11px solid rgba(0,0,0,0);
}

.tb-question__description {
  padding: 15px;
  background-color: rgba(181,160,131,.2);
  border: 1px solid rgba(51,51,51,.6);
  font-family: Montserrat;
  color: #333;
  font-size: 12px;
  line-height: 1.3;
  position: relative;
}

.tb-question__description-wrapper {
  display: none;
  position: absolute;
  bottom: 35px;
  left: -20px;
  right: -20px;
  box-sizing: border-box;
  background-color: #e8e4da;
}

.tb-navigation {
  display: flex;
  align-items: center;
  height: 20px;
}

.tb-navigation__back {
  background: url("../assets/img/icons/back-arrow.svg") no-repeat 50%;
  height: 20px;
  width: 20px;
}

.tb-navigation__back:hover {
  cursor: pointer;
}

.tb-form-group {
  max-width: 300px;
  margin: 35px auto 25px;
}

.tb-input-row {
  position: relative;
  margin-bottom: 35px;
}

.tb-input-row input[type=number] {
  -moz-appearance: textfield;
}

.tb-input-row input[type=number]::-webkit-inner-spin-button,
.tb-input-row input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tb-input-row label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-size: 12px;
  color: #333;
  font-family: Montserrat-Bold;
  font-weight: bold;
  padding-left: 10px;
}

.tb-input-row_cpu .tb-cpu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0eee6;
  padding: 0 18px;
  height: 75px;
}

.tb-input-row_cpu .tb-cpu-container input {
  max-width: 20%;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-top: 0;
  border-bottom: 1px solid #979797;
  font-family: Roboto-Mono;
  font-weight: bolder;
  font-size: 45px;
  height: 45px;
  line-height: 45px;
  padding: 0;
  margin-right: 15px;
  text-align: center;
  background-color: #f0eee6;
  border-bottom: 1px solid #979797;
  box-shadow: none;
}

@media only screen and (max-width: 450px) {
  .tb-input-row_cpu .tb-cpu-container input {
    height: 55px !important;
  }
}

.tb-input-row_cpu .tb-cpu-container input:last-of-type {
  margin-right: 0;
}

.tb-input-row_cpu .tb-cpu-container input:focus {
  background-color: #f0eee6;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.tb-input-row input {
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  line-height: 30px;
  border: 0;
  border-bottom: 1px solid #979797;
  background-color: inherit;
  padding-left: 10px;
  border-radius: 0;
  font-family: Montserrat;
  font-size: 18px;
  color: #333;
}

.tb-input-row input:-webkit-autofill {
  background-color: #e8e4da !important;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0 1000px #e8e4da inset;
}

.tb-input-row input:focus {
  background-color: inherit !important;
}

.tb-input-row__error {
  display: inline-block;
  position: absolute;
  top: 55px;
  left: 10px;
  color: #da4725;
  font-size: 12px;
  font-family: Montserrat;
}

.tb-input-row__password-strength {
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
}

.tb-input-row__password-strength-line {
  height: 5px;
}

.tb-input-row__password-strength-line_strong {
  background: url("../assets/img/icons/password-strength-sterk.svg") 0 0;
}

.tb-input-row__password-strength-line_weak {
  background: url("../assets/img/icons/password-strength-zwak.svg") 0 0 no-repeat;
}

.tb-input-row__password-strength-status {
  padding: 5px 10px 0 0;
  font-family: Montserrat;
  font-size: 12px;
  color: #9a9183;
  text-align: right;
}

.tb-input-row__icon {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: -30px;
  top: 35px;
}

.tb-input-row__icon_checkbox-field {
  top: 0;
}

.tb-input-row__icon_mail {
  background: url("../assets/img/icons/email.svg") no-repeat 50%;
}

.tb-input-row__icon_link {
  background: url("../assets/img/icons/link.svg") no-repeat 50%;
}

.tb-input-row__icon_password {
  background: url("../assets/img/icons/password.svg") no-repeat 50%;
}

.tb-input-row__icon_person {
  background: url("../assets/img/icons/person.svg") no-repeat 50%;
}

.tb-input-row__icon_phone {
  background: url("../assets/img/icons/phone.svg") no-repeat 50%;
}

.tb-input-row__icon_field-status {
  width: 15px;
  height: 15px;
  right: -25px;
  left: auto;
}

.tb-input-row__icon_field-status-success {
  background: url("../assets/img/icons/check-small.svg") no-repeat 50%;
}

.tb-input-row__icon_field-status-error {
  background: url("../assets/img/icons/error-small.svg") no-repeat 50%;
}

.tb-checkbox-row {
  display: flex;
  align-items: center;
  position: relative;
  height: 20px;
}

.tb-checkbox-row input[type=checkbox] {
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  visibility: hidden;
}

.tb-checkbox-row label {
  font-family: Montserrat;
  font-size: 12px;
  margin: 0 0 0 10px;
  line-height: 20px;
  max-width: 88%;
}

.tb-checkbox-row label span {
  font-family: Montserrat-Bold;
  text-decoration: underline;
  font-weight: 600;
}

.tb-checkbox-row label:hover {
  cursor: pointer;
}

.tb-checkbox-row_multiline {
  height: 60px;
  align-items: flex-start;
}

.tb-checkbox-row__icon {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.tb-checkbox-row__icon:hover {
  cursor: pointer;
}

.tb-checkbox-row__icon_unchecked {
  background: url("../assets/img/icons/checkbox-unchecked.svg") no-repeat 50%;
}

.tb-checkbox-row__icon_checked {
  background: url("../assets/img/icons/checkbox-checked-darkgreen.svg") no-repeat 50%;
}

.tb-checkbox-row_invisible>* {
  display: none;
}

.tb-button-row {
  text-align: right;
  margin-top: 25px;
}

.tb-button-row_margin-increased {
  margin-top: 65px;
}

.tb-button {
  display: inline-block;
  padding: 8px 22px;
  text-align: center;
  vertical-align: center;
  height: 36px;
  min-width: 120px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: Montserrat !important;
  font-size: 16px;
  line-height: 16px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid;
}

.tb-button_small {
  height: 32px;
  font-size: 12px;
  padding: 8px 14px;
}

.tb-button:hover {
  cursor: pointer;
}

.tb-button_landing {
  width: 100%;
  max-width: 260px;
  border-radius: 25px;
  padding: 0;
}

.tb-button_green {
  background-color: #3f7a19;
  border-color: #3f7a19;
  color: #e8e4da;
}

.tb-button_green:disabled {
  border: 0;
  background-color: rgba(63,122,25,.4);
  cursor: not-allowed;
}

.tb-button_red {
  background-color: #da4725;
  border-color: #da4725;
  color: #e8e4da;
  min-width: 80px;
}

.tb-button_red:hover {
  background-color: #c9302c;
  border-color: #c9302c;
}

.tb-button_darkpurple {
  border-radius: 6px;
  border: solid 0px;
  background-color: rgba(0,0,0,.25);
  color: #fff;
}

.tb-button_purple {
  background-color: #fff;
  color: #8356ac;
  min-width: 80px;
}

.tb-button_purple_white {
  border-color: #fff;
}

.tb-button_purple:disabled {
  border: 0;
  color: #d8d8d8;
  border: solid 1px #d8d8d8;
  cursor: not-allowed;
}

.tb-button_ipurple {
  background-color: #8356ac;
  color: #fff;
  min-width: 80px;
  border-color: #fff;
}

.tb-button_blue {
  background-color: #fff;
  color: #1e6595;
  border-radius: 8px;
  border: solid 1px #1e6595;
}

.tb-button_blue:disabled {
  border: 0;
  color: #d8d8d8;
  border: solid 1px #d8d8d8;
  cursor: not-allowed;
}

.tb-button_french-blue-outline {
  background-color: #fff;
  color: #3f51b5;
  border-radius: 8px;
  border: solid 1px #3f51b5;
}

.tb-button_french-blue-outline:disabled {
  border: 0;
  color: #d8d8d8;
  border: solid 1px #d8d8d8;
  cursor: not-allowed;
}

.tb-button_iblue {
  color: #fff;
  border: 1px solid #fff;
  background-color: #1d6494;
}

.tb-button_iblue:disabled {
  border: 0;
  color: #d8d8d8;
  border: solid 1px #d8d8d8;
  cursor: not-allowed;
}

.tb-button_iblack {
  color: #fff;
  border: 1px solid #fff;
  background-color: #000;
}

.tb-button_iblack:disabled {
  border: 0;
  color: #d8d8d8;
  border: solid 1px #d8d8d8;
  cursor: not-allowed;
}

.tb-button_iblack {
  color: #fff;
  border: 1px solid #fff;
  background-color: #000;
}

.tb-button_iblack:disabled {
  border: 0;
  color: #d8d8d8;
  border: solid 1px #d8d8d8;
  cursor: not-allowed;
}

.tb-button_lightgreen {
  color: #fff;
  border: 1px solid #fff;
  background-color: #3d7c0c;
}

.tb-button_lightgreen:disabled {
  border: 0;
  background-color: #e4e7e8;
  color: #ccc;
  border: solid 1px #d8d8d8;
  cursor: not-allowed;
}

.tb-button_gray {
  background-color: #ddd6c8;
  color: #2e2d2b;
  border-color: #9f9d98;
}

.tb-button.disabled {
  pointer-events: none;
  cursor: initial;
}

@media only screen and (min-device-width: 340px)and (max-device-width: 768px)and (orientation: landscape) {
  .tb-content {
    align-items: initial;
    overflow-y: scroll;
  }
}

@media only screen and (max-device-width: 768px)and (orientation: portrait) {
  .tb-content {
    overflow-y: hidden;
  }
}

@media only screen and (max-device-width: 450px) {
  .tb-content {
    position: static;
  }
}

@media (min-width: 0px)and (max-width: 450px) {
  .tb-content__inner {
    min-height: 100vh;
    max-height: unset;
    width: 100vw;
    height: auto;
    border-radius: 0;
    padding: 20px 40px;
  }
}

@media (min-width: 0px)and (max-width: 450px) {
  .tb-content__inner_long-text {
    max-height: 100vh;
    overflow-y: auto;
  }
}

@media (max-width: 450px) {
  .tb-text {
    margin: 20px auto;
  }
}

@media (max-width: 450px) {
  .tb-text_version {
    position: absolute;
    margin: 0 auto;
    bottom: 75px;
    left: 0;
    right: 0;
  }
}

@media (max-width: 350px) {
  .tb-text_version {
    bottom: 60px;
  }
}

