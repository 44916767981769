.tb-button{
  display: inline-block;
  padding: 8px 22px;
  text-align: center;
  vertical-align: center;
  height: 36px;
  min-width: 120px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: Montserrat !important;
  font-size:16px;
  line-height: 16px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid;
  &_small{
    height: 32px;
    font-size: 12px;
    padding: 8px 14px;
  }
  &:hover{
    cursor: pointer;
  }
  &_landing{
    width: 100%;
    max-width: 260px;
    border-radius:25px;
    padding: 0;
  }
  &_green{
    background-color:$darkgreen;
    border-color:$darkgreen;
    color: $cremewhite;
    &:disabled {
      border:0;
      background-color: $darkgreen40;
      cursor: not-allowed;
    }
  }
  &_red{
    background-color: $warning;
    border-color: $warning;
    color: $cremewhite;
    min-width: 80px;
    &:hover{
      background-color: #c9302c;
      border-color: #c9302c;
    }
  }
  &_darkpurple {
    border-radius: 6px;
    border: solid 0px;
    background-color: rgba(0, 0, 0, 0.25);
    color: #fff;
  }
  &_purple{
    background-color: white;
    color: #8356ac;
    min-width: 80px;
    &_white{
      border-color: white;
    }
    &:disabled {
      border:0;
      color: $lightestgrey;
      border: solid 1px $lightestgrey;
      cursor: not-allowed;
    }
  }
  &_ipurple{
    background-color: #8356ac;
    color: white;
    min-width: 80px;
    border-color: white;
  }
  &_blue{
    background-color: white;
    color: #1e6595;
    border-radius: 8px;
    border: solid 1px #1e6595;
    &:disabled {
      border:0;
      color: $lightestgrey;
      border: solid 1px $lightestgrey;
      cursor: not-allowed;
    }
  }
  &_french-blue-outline{
    background-color: white;
    color: #3f51b5;
    border-radius: 8px;
    border: solid 1px #3f51b5;
    &:disabled {
      border:0;
      color: $lightestgrey;
      border: solid 1px $lightestgrey;
      cursor: not-allowed;
    }
  }
  &_iblue{
    color: white;
    border: 1px solid #fff;
    background-color: #1d6494;
    &:disabled {
      border:0;
      color: $lightestgrey;
      border: solid 1px $lightestgrey;
      cursor: not-allowed;
    }
  }
  &_iblack{
    color: white;
    border: 1px solid #fff;
    background-color: #000;
    &:disabled {
      border:0;
      color: $lightestgrey;
      border: solid 1px $lightestgrey;
      cursor: not-allowed;
    }
  }

  &_iblack{
    color: white;
    border: 1px solid #fff;
    background-color: #000;
    &:disabled {
      border:0;
      color: $lightestgrey;
      border: solid 1px $lightestgrey;
      cursor: not-allowed;
    }
  }
  &_lightgreen{
    color: white;
    border: 1px solid #fff;
    background-color: #3D7C0C;
    &:disabled {
      border:0;
      background-color: #e4e7e8;
      color: #cccccc;
      border: solid 1px $lightestgrey;
      cursor: not-allowed;
    }
  }
  &_gray{
    background-color:#ddd6c8;
    color:#2e2d2b;
    border-color:#9f9d98;
  }
  &.disabled {
    pointer-events: none;
    cursor: initial;
  }
}
