@import '../../../styles/colors';
@import '../../../styles/variables.scss';
.heartbeat{
  height: 100%;
  white-space: nowrap;
  overflow: scroll;
  &_localspinner {
    &_graph {
      top: 30%;
      left: 50%;
    }
    &_beat{
      top:50%;
      left:5%;
    }
    transform:translateY(-50%) translateX(-50%);
    z-index:9999;
    position:absolute;
    i {
      font-size:12rem;
      color:$darkgrey80;
    }
    &-frame{
      z-index:9999;
      position: absolute;
      width:100%;
      height: 100%;
    }
  }

  &_info{
    width: 80vw;
    margin: auto;
    margin-top: 36px;
  }
  &_data {
    width: 80vw;
    margin: auto;
    display: flex;
    flex-flow: row nowrap;
  }
  &_timepicker {
    padding-top: 16px;
    padding-bottom: 16px;
    width: 100%;
    min-height: 224px;
    background-color: #d8f4ff;;
    display: flex;
    flex-flow: row nowrap;
    font-family: Montserrat;
    font-size: 14px;
    &_type {
      width: 20%;
      border-right: 3px solid #fff;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
    }
    &_button {
      width: 80%;
      display: flex;
      flex-flow: column nowrap;
    }
    &_row {
      width: 80%;
      display: flex;
      flex-flow: row nowrap;
    }
    &_column {
      margin-right: 128px;
      margin-left: 32px;
      line-height: 24px;
      display: flex;
      flex-flow: column nowrap;
      &_xs {
        margin-right: 8px;
        margin-left: 8px;
      }
      &_tr {
        height: 36px;
        padding-bottom: 16px;
      }
    }
    &_content {
      margin-left: 32px;
      justify-content: center;
      display: flex;
      flex-flow: column nowrap;
    }
  }
  &_robot{
    display: flex;
    overflow: ellipsis;
    flex-flow: row nowrap;
    border: 1px solid #d0d0d0;
    flex: 1 1 auto;
    width: 80vw;
    padding-top: 16px;
    padding-bottom: 16px;
    justify-content: space-between;
    overflow-x: auto;
    &_tr{
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
    }
    &_item{
      height: 35px;
      width: 190px;
      line-height: 35px;
      padding-right: 16px;
      margin-bottom: 18px;
      font-family: Roboto-Mono;
    }
    &_button {
      width: 175px;
    }
  }
  &_title {
    width: 80vw;
    margin: auto;
    display: flex;
    flex-flow: row nowrap;
    margin-top: 16px;
    &_margin {
      margin-right: 36px;
    }
  }
  &_list {
    display: flex;
    overflow: ellipsis;
    flex-flow: column nowrap;
    border: 1px solid #d0d0d0;
    flex: 1 1 auto;
    height: 600px;
    width: 40vw;
    overflow-y: scroll;
    &_margin {
      margin-right: 36px;
    }
    &_tr {
      width: 100%;
      overflow: ellipsis;
      display: flex;
      flex-flow: row nowrap;
    }
    &_td {
      overflow: hidden;
      text-overflow: ellipsis;
      height: 35px;
      line-height: 35px;
      padding-left: 36px;
      padding-right: 0px;
    }
    &_th {
      font-family: Montserrat;
      font-size: 16px;
      -webkit-box-shadow:  0 3px 5px 0 rgba(0, 0, 0, 0.5);
      -moz-box-shadow:  0 3px 5px 0 rgba(0, 0, 0, 0.5);
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.5);
      font-weight: bold;
      color: #fff;
      text-align: center;
      line-height: 32px;
      background-color: #1e6595;
      width: 100%;
      z-index: 2;
      &_logs {
        background-color: #000;
      }
    }
    &_data {
      overflow-y: scroll;
      &_row {
        border-bottom: 1px solid #d0d0d0;
        font-family: Roboto-Mono;
        font-weight: normal;
        color: rgba(51, 51, 51, 0.87);
        font-size: 14px;
        position: relative;
        min-height: 36px;
      }
    }
  }
}
.graph{
  width: 80%;
  height: 215px;
  position: relative;
}
.detailed-robot{
  height: 480px;
  display: inline-block;
}
.detailed-subscription{
  height: 480px;
  display: inline-block;
}

.chart-container {
  padding-top: 32px;
  position: relative;
  margin: auto;
  height: 250px;
  width: 80vw;
}

.json-formatter-row {
  margin-left: 1em;
}

.children {
  &.empty {
    opacity: 0.5;
    margin-left: 1em;

    &.object:after { content: "No properties"; }
    &.array:after { content: "[]"; }
  }
}

.string {
  color: green;
  white-space: pre;
  word-wrap: break-word;
}
.number { color: blue; }
.boolean { color: red; }
.null { color: #855A00; }
.undefined { color: rgb(202, 11, 105); }
.function { color: #FF20ED; }
.date { background-color: fade(black, 5%); }
.url {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.bracket { color: blue; }
.key {
  color: #00008B;
  cursor: pointer;
}
.constructor-name {
  cursor: pointer;
}

.toggler {
  font-size: 0.8em;
  line-height: 1.2em;
  vertical-align: middle;
  opacity: 0.6;
  cursor: pointer;

  &:after {
    display: inline-block;
    transition: transform 100ms ease-in;
    content: "►";
  }

  &.open:after{
    transform: rotate(90deg);
  }
}
