@import '../../../../styles/colors.scss';

.tb-table {
  display: flex;
  overflow: ellipsis;
  flex-flow: column nowrap;
  border-bottom: 1px solid #d0d0d0;
  flex: 1 1 auto;
  height: 100%;
  &_tr {
    width: 100%;
    overflow: ellipsis;
    display: flex;
    flex-flow: row nowrap;
  }
  &_th {
    font-family: Montserrat;
    font-size: 16px;
    -webkit-box-shadow:  0 3px 5px 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow:  0 3px 5px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.5);
    font-weight: bold;
    color: #fff;
    z-index: 2;
    &_subscriptions {
      background-color: #8356ac;
    }
    &_organisations {
      background-color: #3f51b5;
      &_dark {
        background-color: #2b3677;
      }
    }
    &_robots {
      background-color: #1e6595;
    }
    a { text-decoration: none; color:white; }
    a:visited { text-decoration: none; color:white; }
    a:hover { text-decoration: none; color:white; }
    a:focus { text-decoration: none; color:white; }
    a:hover, a:active { text-decoration: none; color:white }
  }
  &_td {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 50px;
    line-height: 50px;
    padding-left: 36px;
    padding-right: 0px;
    min-width: 200px;
    max-width: 200px;
    &_sort-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &_icon {
      width: 50px;
    }
    &_sort-select {
      background-color: rgba(0, 0, 0, 0.25);
    }
    &_relation {
      min-width: 250px;
      max-width: 250px;
      overflow: hidden;
      &_long {
        line-height: 25px;
        overflow-wrap: break-word;
        white-space: pre-line;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    &_robots {
      min-width: 120px;
      max-width: 120px;
    }
    &_subscriptions {
      min-width: 120px;
      max-width: 120px;
    }
    &_robot {
      min-width: 200px;
      max-width: 200px;
    }
    &_servicePackage {
      min-width: 130px;
      max-width: 130px;
    }
    &_premiumPackage {
      min-width: 210px;
      max-width: 210px;
    }
    &_box {
      min-width: 100px;
      max-width: 100px;
      &_row {
        font-weight: 300;
        opacity: 0.87;
      }
    }
    &_check {
      min-width: 160px;
      max-width: 160px;
      &_checked {
        width: 36px;
        display: block;
        background: url('assets/img/icons/checkmark.svg') no-repeat 50%;
      }
      &_notchecked {
        width: 36px;
        display: block;
        background: url('assets/img/icons/dot.svg') no-repeat 50%;
      }
    }
    &_length {
      min-width: 110px;
      max-width: 110px;
    }
    &_date {
      min-width: 150px;
      max-width: 150px;
      &_red {
        color: #fa3505;
      }
      &_yellow {
        color: #e6c029;
      }
    }
    &_reference {
      min-width: 160px;
      max-width: 160px;
    }
  }
  &_data {
    height: calc(100% - 158px);
    overflow-y: scroll;
    &_row {
      border-bottom: 1px solid #d0d0d0;
      font-family: Roboto-Mono;
      font-weight: normal;
      color: rgba(51, 51, 51, 0.87);
      font-size: 14px;
      &_red {
        color: #fa3505;
      }
      &_yellow {
        color: #e6c029;
      }
      &_grey {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
  &_ship {
    height: 16px;
    font-size: 14px;
  }
}
.tb-table_th > .tb-table_td {
  white-space: normal;
}
