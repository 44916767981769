@import '../../../../styles/colors.scss';
@import '../../../../styles/variables.scss';

.tb-header {
  display: flex;
  height: 108px;
  flex-flow: row nowrap;
  border-bottom: 1px solid #d0d0d0;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
  &_right-button {
    margin-right: 110px
  }
  &_title-right {
    margin-right: 110px;
    width: 230px;
    font-size: 36px;
    font-family: Montserrat;
    font-weight: bold;
    color: #fdfdfd;
  }
  &_dropdown {
    font-family: Montserrat;
    font-size: 16px;
    width: 250px;
    &_button-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &_overview {
    background-color: #8356ac;
  }
  &_organisations {
    background-color: #3f51b5;
  }
  &_robot {
    background-color: #1d6494;
  }
  &_search-box {
    display: flex;
    flex-flow: row nowrap;
    padding-left: 187px;
    &__title {
      width: 230px;
      display: flex;
      flex-flow: row nowrap;
      flex-grow: 1;
      flex-basis: 0;
      font-size: 24px;
      font-family: Montserrat;
      font-weight: 600;
      color: #fdfdfd;
    }
    &__item{
      display: flex;
      &_search{
        justify-content: space-between;
        padding: 0 8px;
        min-width: 380px;
        max-width: 550px;
        input{
          width: 350px;
          margin-right: 8px;
          height: 36px;
          padding: 10px 15px;
          border: 1px;
          border-radius: 4px;
          box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
          font-family: Montserrat;
          font-size: 14px;
        }
      }
    }
  }
}
