@import '../../../../styles/colors.scss';
.tb-datalist {
  position: relative;
  margin: 0 auto;
  &_items {
    position: absolute;
    max-height: 190px;
    overflow-y: scroll;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    z-index: 10;
    &_robot {
      font-family: Roboto-Mono;
      max-height: 145px;
    }
    &_item {
      margin-left: 8px;
      height: 48px;
      line-height: 48px;
      overflow-x: hidden;
      overflow-y: hidden;
      text-overflow: ellipsis;
      a {
        display: block;
        text-decoration: none;
        color: #9e9e9e;
      }
    }
    &_item:hover{
      background: #f3f8f8;
    }
  }
  .disabled {
    color: #d3d3d3
  }
}
