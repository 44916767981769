@import '../../../../styles/colors.scss';
@import '../../../../styles/variables.scss';

.header{
  position: relative;
  height: $header_height;
  box-sizing: border-box;
  background-color: $darkgrey;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
  z-index: 3;
  &__inner{
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    padding: 0 100px 0 40px;
    margin:0;
    &_align{
      &-end{
        justify-content: flex-end;
      }
      &-space-between{
        justify-content: space-between;
      }
    }
  }
  &__item{
    display: flex;
    a { text-decoration: none; color:white; }
    a:visited { text-decoration: none; color:white; }
    a:hover { text-decoration: none; color:white; }
    a:focus { text-decoration: none; color:white; }
    a:hover, a:active { text-decoration: none; color:white }
    &_center{
      justify-content: center;
      flex:1;
    }
    &_left{
      flex:1
    }
    &_right{

    }
    &_space-between{
      justify-content: space-between;
    }
    &_search{
      justify-content: space-between;
      padding: 0 20px;
      width: 40%;
      min-width: 380px;
      max-width: 550px;
      input{
        width: 65%;
        height: 36px;
        padding: 10px 15px;
        border: 1px;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        font-family: Montserrat;
        font-size: 14px;
      }
    }
  }
  &__title{
    font-family: Montserrat;
    font-size: 18px;
    color: #e6e2d8;
  }
  &__text{
    font-family: Montserrat-Bold;
    font-size: 20px;
    color: white;
    text-transform: uppercase;
    padding-left: 8px;
    line-height: 36px;
    &_dark{
      opacity: 0.4;
    }
  }
  &__icon{
    display: block;
    width: 36px;
    height: 36px;
    &_search{
      background: url('assets/img/icons/search.svg') no-repeat 50%;
    }
    &_tinybots{
      background: url('assets/img/icons/TB-DB_icon_Logo.svg') no-repeat 50%;
    }
  }
}
.dropdown{
  position: absolute;
  top: 60px;
  right: 0;
  width: 295px;
  background-color: $darkgrey;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
  &__section{
    min-height: 60px;
    border-bottom: 1px solid #575755;
    &:last-of-type{
      border-bottom: 0;
    }
  }
  &__item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 60px;
    font-family: Montserrat;
    color: #d2cfc6;
    font-size: 14px;
    &:hover{
      background-color: #4e4e4e;
    }
    &_inactive{
      &:hover{
        cursor: default;
        background-color: $darkgrey;

      }
      p{
        color:#7f7d77;
      }
    }
    &-icon{
      margin:0 20px;
      background-size:cover;
      &_tessa{
        width: 15px;
        height:18px;
        background-image: url("assets/img/icons/tessa-grey.svg");
      }
      &_logout{
        width: 16px;
        height:13px;
        background-image: url("assets/img/icons/exit.svg");
      }
      &_overview{
        width: 16px;
        height:13px;
        background-image: url("assets/img/icons/overview.svg");
      }
      &_orders{
        width: 20px;
        height: 21px;
        background-image: url("assets/img/icons/tb-db-icon-order-light.svg");
      }
      &_switch {
        width: 24px;
        height: 24px;
        background-image: url("assets/img/icons/compare_arrows-24px.svg");
        filter: invert(100%);
      }
      &_orgs {
        width: 24px;
        height: 24px;
        background-image: url("assets/img/icons/relation.svg");
        filter: invert(100%);
      }
      &_analytics {
        width: 24px;
        height: 24px;
        background-image: url("assets/img/icons/TB_Dashboard_Graph_hamburger-24px.svg");
      }
      &_tablet_enrollment {
        color: #d2cfc6;
        width: 24px;
        height: 24px;
        background-image: url("assets/img/icons/material-qr.svg");
      }
      &_heartbeat {
        width: 20px;
        height: 18px;
        background-image: url("assets/img/icons/TB-DB_icon_heartbeat.svg");
      }
      &_new-dashboard{
        width: 19px;
        height:13px;
        background-image: url("assets/img/icons/new-dashboard.svg");
      }

    }
  }
  &-button{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 60px;
    z-index: 1;
    background-color: $darkgrey;
    transition: background-color .3s ease;
    &:hover{
      background-color: #4e4e4e;
      cursor: pointer;
    }
    &_fixed{
      position: absolute;
      right: 0;
      top:0;
    }
    &_active{
      background-color: #3d7c0c;
      &:hover{
        background-color: #3d7c0c;
      }
    }
    &__wrapper{
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    &__inner{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 20px;
      height: 12px;
    }
    &__icon-bar{
      background-color: $cremewhite;
      height: 2px;

    }
  }
}
