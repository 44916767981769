@import '../../../../styles/colors.scss';
.spinner {
  display:inline-block;
  position: absolute;

  top:50%;
  left:50%;
  transform:translateY(-50%) translateX(-50%);
  z-index:9999;

  i {
    font-size:12rem;
    color:$darkgrey80;
  }
  &-frame{
    position:fixed;
    left:0;top:0;right:0;bottom:0;
    background-color: $lightgrey60;
    z-index:9999;
  }
}